import React from 'react';

import CennikContainer from '@containers/cennik';
import useConfiguratorQuery from '@graphql/queries/ConfiguratorQuery';
import useCennikQuery from '@graphql/queries/CennikQuery';
import MetaPage from '@components/common/MetaPage';
import { LocationDependent } from '@content/types/general';
import DataLayer from '@components/layout/DataLayer';
import { PageContext } from '@content/types/pageContext';
import useSettingsQuery from '@graphql/queries/SettingsQuery';

interface CennikPageProps extends LocationDependent {
  pageContext: PageContext;
}

export default (props: CennikPageProps) => {
  const cennikQuery = useCennikQuery()[props.pageContext.langcode];
  const administration = useSettingsQuery();

  return (
    <>
      <MetaPage
        title={cennikQuery ? cennikQuery.seo.title : ''}
        description={cennikQuery ? cennikQuery.seo.description : ''}
        image={cennikQuery ? cennikQuery.seo.image : undefined}
      />
      <DataLayer location={props.location} />
      <CennikContainer
        {...useConfiguratorQuery()}
        location={props.location}
        settings={cennikQuery}
        langcode={props.pageContext.langcode}
        urls={props.pageContext.allUrls}
        administration={administration}
      />
    </>
  );
};
