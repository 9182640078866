import React, { useMemo } from 'react';

import { Funding, ConfiguratorBasket } from '@store/types';
import { CennikActions } from '@store/actions';

import Container from '@components/layout/Container';
import { CennikSettings } from '@content/types/cennik';
import Image from 'gatsby-image';
import styled from '@emotion/styled';
import TooltipIcon from '@components/common/TooltipIcon';
import { breakpoints, colors, respondFrom, css } from '@styles';
import Tooltip from '@components/common/Tooltip';

const StyledCennikFundings = styled.section`
  margin-bottom: 40px;
`;

const StyledFunding = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledImage = styled(Image)`
  margin-bottom: 10px;
  max-width: 150px;
  max-height: 45px;

  ${respondFrom(
    breakpoints.md,
    css`
      max-width: 182px;
      max-height: 55px;
    `
  )};
`;

const StyledWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

const StyledName = styled.div`
  font-size: 14px;
  font-weight: normal;
  color: ${colors.text.default};
`;

const StyledButton = styled.button<StyledButtonProps>`
  font-size: 14px;
  line-height: 1px;
  font-weight: normal;
  text-transform: uppercase;
  white-space: nowrap;
  color: ${({ isAdded }) => (isAdded ? colors.black : colors.green.summary)};
  border: 2px solid ${({ isAdded }) => (isAdded ? '#a7a7a7' : colors.green.summary)};
  background-color: ${colors.white};
  margin-left: 15px;
  cursor: pointer;
  height: 40px;
  padding: 0 20px;
  min-width: 102px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
  &:hover {
    ${({ isAdded }) =>
      !isAdded &&
      css`
        background-color: ${colors.green.summary};
        color: ${colors.white};
      `};
  }
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
`;

interface StyledButtonProps {
  isAdded?: boolean;
  disabled?: boolean;
}

export interface CennikFundingsProps {
  fundings: Funding[];
  currentFundings: Funding[];
  cennikActions: typeof CennikActions;
  basket: ConfiguratorBasket;
  settings: CennikSettings;
  langcode: string;
  urls: Array<string>;
}

export default (props: CennikFundingsProps) => {
  const pPole = useMemo(() => {
    return props.settings.configuratorFields && props.settings.configuratorFields.field_proste_pola;
  }, [props]);

  const texts = useMemo(() => {
    return props.settings.sections && props.settings.sections.texts;
  }, [props.settings]);

  const handleFundingClick = (funding: Funding, isAdded: boolean) => () => {
    if (funding.disabled) return;
    isAdded
      ? props.cennikActions.basketRemoveFunding(funding)
      : props.cennikActions.basketAddFunding(funding);
  };

  return (
    <StyledCennikFundings>
      <Container>
        {props.fundings &&
          props.fundings
            .filter(funding => !funding.disabled)
            .map(funding => {
              const isFundingAdded = !!props.currentFundings.find(f => f.id === funding.id);

              return (
                <StyledFunding key={funding.id}>
                  {funding.imageLarge && (
                    <StyledImage
                      {...funding.imageLarge.childImageSharp}
                      imgStyle={{ objectFit: 'contain' }}
                    />
                  )}
                  <StyledWrapper>
                    <StyledName>
                      {`${texts && texts[35] ? texts[35] : ''} ${funding.name}`}
                    </StyledName>
                    {funding.description && (
                      <TooltipIcon
                        data-html={true}
                        data-tip={funding.description}
                        data-for="tooltipFounding"
                        data-place={'top'}
                      />
                    )}
                  </StyledWrapper>
                  <StyledButton
                    isAdded={isFundingAdded}
                    onClick={handleFundingClick(funding, isFundingAdded)}
                  >
                    {isFundingAdded
                      ? pPole && pPole[132]
                        ? pPole[132]
                        : ''
                      : pPole && pPole[131]
                      ? pPole[131]
                      : ''}
                  </StyledButton>
                </StyledFunding>
              );
            })}
        <Tooltip width="medium" theme="light" id={`tooltipFounding`} effect="solid" border />
      </Container>
    </StyledCennikFundings>
  );
};
