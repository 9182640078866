import get from 'lodash.get';

export const cennikTransformers = page => {
  const cennikLanguages = {};

  page.allSiteSettingEntityPriceList.edges.forEach(edge => {
    const cennikData = edge.node;

    cennikLanguages[cennikData.langcode] = {
      seo: {
        title: get(cennikData, 'field_meta_title', ''),
        description: get(cennikData, 'field_meta_description', ''),
        image: get(cennikData, 'relationships.field_main_image.localFile.publicURL'),
      },
      hero: {
        title: get(
          cennikData,
          'relationships.field_components[0].field_paragraph_headline.value',
          ''
        ),
        paragraph: get(
          cennikData,
          'relationships.field_components[0].field_branches_text.value',
          ''
        ),
        button: get(cennikData, 'relationships.field_components[0].field_branches_button_text', ''),
        imageAlt: get(cennikData, 'relationships.field_components[0].field_branches_image.alt', ''),
        image: get(
          cennikData,
          'relationships.field_components[0].relationships.field_branches_image.localFile',
          null
        ),
        imageMobile: get(
          cennikData,
          'relationships.field_components[0].relationships.field_branches_image_mobile.localFile',
          null
        ),
      },
      sections: {
        title1: get(cennikData, 'field_pl_header_1.value', ''),
        title2: get(cennikData, 'field_pl_header_2.value', ''),
        title3: get(cennikData, 'field_pl_header_3.value', ''),
        title4: get(cennikData, 'field_pl_header_4.value', ''),
        saveingUp: {
          title: get(cennikData, 'field_footer_left.value', ''),
          paragraph: get(cennikData, 'field_footer_right.value', ''),
        },
        badge: get(cennikData, 'field_texts[0]', ''),
        fundings: {
          button1: get(cennikData, 'field_texts[1]', ''),
          button2: get(cennikData, 'field_texts[2]', ''),
          disabledText1: get(cennikData, 'field_texts[3]', ''),
          disabledText2: get(cennikData, 'field_texts[4]', ''),
          disabledText3: get(cennikData, 'field_texts[5]', ''),
          titleButtonLabel: get(cennikData, 'field_texts[6]', ''),
          titleButtonHref: get(cennikData, 'field_texts[7]', ''),
          f1: {
            paragraph: get(cennikData, 'field_texts[8]', ''),
            image: get(page, 'image1', null),
            imageAlt: get(cennikData, 'field_texts[9]', ''),
          },
          f2: {
            paragraph: get(cennikData, 'field_texts[10]', ''),
            image: get(page, 'image2', null),
            imageAlt: get(cennikData, 'field_texts[11]', ''),
          },
          f3: {
            paragraph: get(cennikData, 'field_texts[12]', ''),
            image: get(page, 'image3', null),
            imageAlt: get(cennikData, 'field_texts[13]', ''),
          },
        },
        tooltip1: get(cennikData, 'field_texts[14]', ''),
        tooltip2: get(cennikData, 'field_texts[15]', ''),
        tooltip3: get(cennikData, 'field_texts[43]', ''),
        summaryPanel: {
          title: get(cennikData, 'field_texts[16]', ''),
          title2: get(cennikData, 'field_texts[17]', ''),
          text1: get(cennikData, 'field_texts[18]', ''),
          text2: get(cennikData, 'field_texts[19]', ''),
          text3: get(cennikData, 'field_texts[20]', ''),
          text4: get(cennikData, 'field_texts[21]', ''),
          text5: get(cennikData, 'field_texts[22]', ''),
          text6: get(cennikData, 'field_texts[23]', ''),
          button: get(cennikData, 'field_texts[24]', ''),
        },
        modal: {
          paragraph: get(cennikData, 'field_texts[25]', ''),
          button1: get(cennikData, 'field_texts[26]', ''),
          button2: get(cennikData, 'field_texts[27]', ''),
        },
        texts: get(cennikData, 'field_texts', [])
      },
    };
  });

  page.allSiteSettingEntityConfigurator.edges.forEach(edge => {
    const configuratorData = edge.node;

    cennikLanguages[configuratorData.langcode].configuratorFields = {
      field_proste_pola: get(configuratorData, 'field_proste_pola', ''),
      field_pola_wysywig: get(configuratorData, 'field_pola_wysywig', ''),
    };
  });

  return cennikLanguages;
};
