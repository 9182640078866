import React from 'react';
import { ConfiguratorBasket, Fiscalization } from '@store/types';
import { CennikActions } from '@store/actions';
import Container from '@components/layout/Container';
import CennikSectionHeader from '@components/cennik/CennikSectionHeader';
import * as utils from '@utils';
import { CennikSettings } from '@content/types/cennik';
import FiscalizationsList from '@components/configurator/fiscalizations/FiscalizationsList';
import styled from '@emotion/styled';

const StyledCennikFiscalizations = styled.section`
  margin-bottom: 20px;
`;

export interface CennikFiscalizationsProps {
  fiscalizations: Fiscalization[];
  cennikActions: typeof CennikActions;
  basket: ConfiguratorBasket;
  settings: CennikSettings;
}

export default (props: CennikFiscalizationsProps) => (
  <StyledCennikFiscalizations>
    <Container>
      <CennikSectionHeader
        title={utils.SafeHtml(props.settings.sections.title3)}
      />
      <FiscalizationsList
        configuratorActions={props.cennikActions}
        basket={props.basket}
        pageSettings={props.settings.configuratorFields}
        fiscalizations={props.fiscalizations}
        hideDesc
        sliderSettings={{
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2.5,
              },
            },
          ],
        }}
      />
    </Container>
  </StyledCennikFiscalizations>
);
