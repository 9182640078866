import Container from '@components/layout/Container';
import styled from '@emotion/styled';
import React, { useMemo } from 'react';
import ButtonLink from '@components/common/ButtonLink';
import { breakpoints, respondFrom, css } from '@styles';
import { CennikSettings } from '@content/types/cennik';
import * as utils from '@utils';

const StyledCennikHelp = styled.section`
  margin-bottom: 60px;
`;

const StyledCennikHelpWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledCennikHelpHeader = styled.h2`
  text-align: center;
  margin-bottom: 30px;
  ${respondFrom(
    breakpoints.lg,
    css`
      margin-bottom: 50px;
    `
  )};
`;

interface CennikHelpProps {
  settings: CennikSettings;
}

const CennikHelp = (props: CennikHelpProps) => {
  const texts = useMemo(() => {
    return props.settings.sections && props.settings.sections.texts;
  }, [props.settings]);

  return (
    <StyledCennikHelp>
      <Container>
        <StyledCennikHelpWrapper>
          <StyledCennikHelpHeader>
            {utils.SafeHtml(texts && texts[41] ? texts[41] : '')}
          </StyledCennikHelpHeader>
          <ButtonLink extend target="_self" to="javascript:callpage('api.widget.open');">
            {texts && texts[42] ? texts[42] : ''}
          </ButtonLink>
        </StyledCennikHelpWrapper>
      </Container>
    </StyledCennikHelp>
  );
};

export default CennikHelp;
