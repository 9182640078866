import React from 'react';

import { Tariff, Funding, ConfiguratorBasket } from '@store/types';
import { CennikActions } from '@store/actions';

import Container from '@components/layout/Container';
import CennikSectionHeader from '@components/cennik/CennikSectionHeader';
import TariffsList from '@components/configurator/tariff/TariffsList';
import { LocationDependent } from '@content/types/general';
import * as utils from '@utils';
import { CennikSettings } from '@content/types/cennik';
import styled from '@emotion/styled';

const StyledCennikTariffs = styled.section`
  margin-bottom: 20px;
`;

export interface CennikTariffsProps extends LocationDependent {
  tariffs: Tariff[];
  cennikActions: typeof CennikActions;
  currentTariff: Tariff | null;
  currentFundings: Funding[];
  basket: ConfiguratorBasket;
  settings: CennikSettings;
}

export default (props: CennikTariffsProps) => (
  <StyledCennikTariffs>
    <Container>
      <CennikSectionHeader title={utils.SafeHtml(props.settings.sections.title2)} />
      <TariffsList
        {...props}
        configuratorActions={props.cennikActions}
        sliderSettings={{
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2.5,
              },
            },
          ],
        }}
        showAdditionalCost
      />
    </Container>
  </StyledCennikTariffs>
);
