import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@styles';

const StyledCennikSectionHeader = styled.header`
  margin-bottom: 30px;
  padding: 25px 30px;
  background-color: ${colors.smoke};
  color: ${colors.text.default};
  line-height: 1.5;
  text-align: center;
  h3 {
    margin-bottom: 0;
  }
`;

interface CennikSectionHeaderProps {
  title?: string | React.ReactElement;
}

export default (props: CennikSectionHeaderProps) => (
  <StyledCennikSectionHeader>
    <h3>{props.title && props.title}</h3>
  </StyledCennikSectionHeader>
);
