import React from 'react';

import Hero from '@components/common/Hero';
import { CennikSettings } from '@content/types/cennik';

interface CennikHeroProps {
  settings: CennikSettings;
}

export default (props: CennikHeroProps) => (
  <Hero
    size="medium"
    image={props.settings.hero.image ? props.settings.hero.image.childImageSharp : undefined}
    imageMobile={
      props.settings.hero.imageMobile ? props.settings.hero.imageMobile.childImageSharp : undefined
    }
    imageAlt={props.settings.hero.imageAlt}
    title={props.settings.hero.title}
    paragraph={props.settings.hero.paragraph}
    buttonText={props.settings.hero.button}
    buttonRoute="#section-paymentype"
    buttonOutside={true}
    theme="dark"
  ></Hero>
);
