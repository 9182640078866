import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';

import actions from '@store/actions';
import CennikComponent, { CennikProps } from '@components/cennik';
import { RootState } from '@store/types';

const mapStateToProps = (state: RootState) => ({
  configurator: state.configurator,
  cennik: state.cennik,
  content: state.content,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  configuratorActions: bindActionCreators(actions.configurator, dispatch),
  cennikActions: bindActionCreators(actions.cennik, dispatch),
  contentActions: bindActionCreators(actions.content, dispatch),
});

interface DCennikProductsProps extends CennikProps {}
const DCennikProducts: React.FC<DCennikProductsProps> = (props: DCennikProductsProps) => (
  <CennikComponent {...props} />
);

const CennikProductsConnected = connect(
  mapStateToProps,
  mapDispatchToProps
)(DCennikProducts);

export default CennikProductsConnected;
