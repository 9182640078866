import { graphql, useStaticQuery } from 'gatsby';
import * as cennikTransformers from '@content/transformers/CennikTransformers';
import { CennikSettings } from '@content/types/cennik';

const CennikQuery = (): CennikSettings => {
  const cennikData = useStaticQuery(graphql`
    query {
      allSiteSettingEntityPriceList {
        edges {
          node {
            langcode
            field_pl_header_1 {
              value
            }
            field_pl_header_2 {
              value
            }
            field_pl_header_3 {
              value
            }
            field_pl_header_4 {
              value
            }
            field_footer_left {
              value
            }
            field_footer_right {
              value
            }
            field_meta_description
            field_meta_title
            field_texts
            relationships {
              field_main_image {
                localFile {
                  publicURL
                }
              }
              field_components {
                field_branches_button_text
                field_branches_image {
                  alt
                }
                field_branches_image_mobile {
                  alt
                }
                field_branches_text {
                  value
                }
                field_paragraph_headline {
                  value
                }
                relationships {
                  field_branches_image {
                    localFile {
                      childImageSharp {
                        fixed(width: 1600, quality: 100) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                  field_branches_image_mobile {
                    localFile {
                      childImageSharp {
                        fixed(width: 800, quality: 100) {
                          ...GatsbyImageSharpFixed
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      allSiteSettingEntityConfigurator {
        edges {
          node {
            langcode
            field_pola_wysywig {
              value
            }
            field_proste_pola
          }
        }
      }

      image1: file(relativePath: { eq: "pb-logo@2x.png" }) {
        childImageSharp {
          fixed(width: 125, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image2: file(relativePath: { eq: "idea-logo@2x.png" }) {
        childImageSharp {
          fixed(width: 125, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      image3: file(relativePath: { eq: "irs-icon@2x.png" }) {
        childImageSharp {
          fixed(width: 125, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return cennikTransformers.cennikTransformers(cennikData);
};

export default CennikQuery;
