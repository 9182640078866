import Container from '@components/layout/Container';
import { CennikSettings } from '@content/types/cennik';
import styled from '@emotion/styled';
import { CennikActions } from '@store/actions';
import { breakpoints, colors, mixins, respondFrom, css } from '@styles';
import React, { useMemo } from 'react';

const StyledCennikPaymentType = styled.section`
  margin-bottom: 30px;
`;

const StyledCennikPaymentTypeHeader = styled.h2`
  text-align: center;
  font-size: 40px;
  font-weight: 300;
  color: ${colors.text.default};
  margin-bottom: 30px;
`;

const StyledCennikPaymentTypeTabs = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  ${respondFrom(
    breakpoints.lg,
    css`
      flex-direction: row;
      align-items: center;
    `
  )};
`;

const StyledCennikPaymentTypeTab = styled.div`
    flex: 1;
    min-height: 102px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    ${mixins.transitionDefault}
    ${mixins.borderOnHover}
    ${mixins.borderWhenActive}
    border: solid 1px ${colors.gray};

    &:not(:last-child) {
        margin-bottom: 15px;
    }

    &:hover, &:active, &.active {
        border-width: 2px;
        background-color: #efefee;
    }

    ${respondFrom(
      breakpoints.lg,
      css`
        &:not(:last-child) {
          margin-right: 15px;
          margin-bottom: 0;
        }

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 100%;
          left: 50%;
          width: 0;
          height: 0;
          border-style: solid;
          visibility: hidden;
          opacity: 0;
        }

        &:before {
          margin-left: -22px;
          border-color: ${colors.blue.default} transparent transparent transparent;
          border-width: 22px;
        }

        &:after {
          margin-left: -20px;
          border-color: #efefee transparent transparent transparent;
          border-width: 20px;
        }

        &:hover,
        &:active,
        &.active {
          &:before,
          &:after {
            visibility: visible;
            opacity: 1;
          }
        }
      `
    )};
`;

const StyledCennikPaymentTypeName = styled.span`
  font-size: 30px;
  font-weight: 500;
  line-height: 1em;
  margin-bottom: 5px;
`;

const StyledCennikPaymentTypeDesc = styled.span`
  font-size: 14px;
  font-weight: 300;
  line-height: 1em;
`;

interface CennikPaymentTypeProps {
  paymentType?: string;
  cennikActions: typeof CennikActions;
  settings: CennikSettings;
}

const CennikPaymentType = (props: CennikPaymentTypeProps) => {
  const texts = useMemo(() => {
    return props.settings.sections && props.settings.sections.texts;
  }, [props.settings]);

  const handlePaymentTypeClick = (paymentType: string) => () => {
    props.cennikActions.setPaymentType(paymentType);
  };

  return (
    <StyledCennikPaymentType id="section-paymentype">
      <Container>
        <StyledCennikPaymentTypeHeader>
          {texts && texts[28] ? texts[28] : ''}
        </StyledCennikPaymentTypeHeader>
        <StyledCennikPaymentTypeTabs>
          <StyledCennikPaymentTypeTab
            onClick={handlePaymentTypeClick('lease')}
            className={props.paymentType === 'lease' ? 'active' : ''}
          >
            <StyledCennikPaymentTypeName>
              {texts && texts[29] ? texts[29] : ''}
            </StyledCennikPaymentTypeName>
            <StyledCennikPaymentTypeDesc>
              {texts && texts[30] ? texts[30] : ''}
            </StyledCennikPaymentTypeDesc>
          </StyledCennikPaymentTypeTab>
          <StyledCennikPaymentTypeTab
            onClick={handlePaymentTypeClick('cash')}
            className={props.paymentType === 'cash' ? 'active' : ''}
          >
            <StyledCennikPaymentTypeName>
              {texts && texts[31] ? texts[31] : ''}
            </StyledCennikPaymentTypeName>
            <StyledCennikPaymentTypeDesc>
              {texts && texts[32] ? texts[32] : ''}
            </StyledCennikPaymentTypeDesc>
          </StyledCennikPaymentTypeTab>
          <StyledCennikPaymentTypeTab
            onClick={handlePaymentTypeClick('leasing')}
            className={props.paymentType === 'leasing' ? 'active' : ''}
          >
            <StyledCennikPaymentTypeName>
              {texts && texts[33] ? texts[33] : ''}
            </StyledCennikPaymentTypeName>
            <StyledCennikPaymentTypeDesc>
              {texts && texts[34] ? texts[34] : ''}
            </StyledCennikPaymentTypeDesc>
          </StyledCennikPaymentTypeTab>
        </StyledCennikPaymentTypeTabs>
      </Container>
    </StyledCennikPaymentType>
  );
};

export default CennikPaymentType;
