import React from 'react';
import styled from '@emotion/styled';

import { respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';
import Button from '@components/common/Button';
import { ConfiguratorDependent, CennikStateDependent } from '@store/types';
import { CennikSettings } from '@content/types/cennik';

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  > * {
    margin-bottom: 10px;
  }

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-direction: row;
      justify-content: space-around;
    `
  )}
`;

interface Props extends ConfiguratorDependent, CennikStateDependent {
  settings: CennikSettings;
  langcode: string;
  urls: Array<string>;
}

export default (props: Props) => (
  <>
    {utils.SafeHtml(props.settings.sections.modal.paragraph)}
    <ButtonsWrapper>
      <Button
        color="text"
        onClick={() => {
          props.configuratorActions.replaceBasket(props.cennik);
          utils.navigate(utils.langLink(props.langcode, props.urls[8]));
        }}
      >
        {utils.SafeHtml(props.settings.sections.modal.button1)}
      </Button>
      <Button
        color="primary"
        onClick={() => {
          utils.navigate(utils.langLink(props.langcode, props.urls[4]));
        }}
      >
        {/* TODO: close modal first*/}
        {utils.SafeHtml(props.settings.sections.modal.button2)}
      </Button>
    </ButtonsWrapper>
  </>
);
