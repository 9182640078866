import React, { Component } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, respondTo, breakpoints, css, mixins } from '@styles';

import Container from '@components/layout/Container';
import Button from '@components/common/Button';
import CennikSummaryPanelContentPrice from '@components/cennik/CennikSummaryPanelContentPrice';
import { ConfiguratorBasket } from '@store/types';
import { CennikActions } from '@store/actions';
import { LeasingInfo } from '@store/content/types';
import { CennikSettings } from '@content/types/cennik';
import { PricesSummaryType } from '@content/types/prices';
import * as utils from '@utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mixins.fontSmoothing};
  color: ${colors.white};
  padding: 15px 0 20px;

  ${respondTo(
    breakpoints.xl,
    css`
      .hide-on-mobile-closed {
        ${mixins.transitionDefault}
        transition-delay: 100ms;
      }

      &.mobile-closed {
        .hide-on-mobile-closed {
          opacity: 0;
          visibility: hidden;
          transform: translateY(10px);
        }
      }
    `
  )}
`;

const WrapperInner = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${respondFrom(
    breakpoints.xl,
    css`
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
    `
  )}
`;

const StyledSummaryPanelText = styled.div`
  font-size: 26px;
  text-align: center;
  padding: 30px 0;
  border-bottom: ${colors.text.medium} solid 1px;

  ${respondFrom(
    breakpoints.xl,
    css`
      text-align: left;
      padding: 10px 30px;
      border-bottom: 0;
    `
  )}
`;

const StyledBuyButtonLink = styled(Button)`
  display: block;
  margin-top: 15px;
  z-index: 3;
  pointer-events: initial;
  margin: 0 auto;

  ${respondFrom(
    breakpoints.xl,
    css`
      align-self: center;
      margin: 0;
    `
  )}
`;

const OpenSummaryButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 30px;
  border: none;
  background: transparent;
  width: 30px;
  height: 30px;
  z-index: 3;
  pointer-events: initial;
  cursor: pointer;

  ${mixins.transitionDefault}

  &:hover {
    transform: translateY(-2px);
  }

  .opened & {
    transform: rotate(180deg);

    &:hover {
      transform: rotate(180deg);
    }
  }

  &:focus {
    outline: none;
  }

  &::before {
    content: '';
    width: 15px;
    height: 15px;
    position: absolute;
    top: 8px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 2px solid ${colors.green.dark};
    border-right: 0;
    border-bottom: 0;
    transform: rotate(45deg);
  }

  ${respondFrom(
    breakpoints.xl,
    css`
      display: none;
    `
  )}
`;

const StyledButtonWrapper = styled.div`
  margin: auto;
  background-color: ${colors.text.default};
`;

const CennikUpperPanel = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${colors.text.default};
  padding: 0 2em;
  z-index: -1;
  transform: translateY(-99%);

  ${mixins.transitionDefault};
  opacity: 1;

  ${respondFrom(
    breakpoints.xl,
    css`
      display: flex;
      align-items: center;
      position: static;
      transform: none;
      opacity: 1;
      padding: 0;
      width: 50%;
    `
  )}

  ${respondFrom(
    breakpoints.xl,
    css`
      width: 65%;
    `
  )}

  &.mobile-closed {
    transform: translateY(0);
    opacity: 0;

    ${respondFrom(
      breakpoints.xl,
      css`
        display: flex;
        position: static;
        transform: none;
        opacity: 1;
        padding: 0;
        width: 50%;
      `
    )}

    ${respondFrom(
      breakpoints.xl,
      css`
        width: 65%;
      `
    )}
  }
`;

const StyledCennikSummaryPanelItem = styled.div`
  text-align: center;
  padding: 25px 0;

  ${respondFrom(
    breakpoints.xl,
    css`
      text-align: left;
      padding: 10px 30px;
      border-bottom: 0;
      border-left: ${colors.text.medium} solid 1px;
    `
  )};
`;

interface ShoppingSummaryProps {
  active: boolean;
  basket: ConfiguratorBasket;
  cennikActions: typeof CennikActions;
  prices: PricesSummaryType;
  paymentType?: string;
  leasingInfo: LeasingInfo;
  settings: CennikSettings;

  onOpenMobile: Function;
  isMobileOpened: boolean;

  onBuyClick: () => void;
}

class ShoppingSummary extends Component<ShoppingSummaryProps> {
  render() {
    const { prices, basket, onOpenMobile, isMobileOpened, settings } = this.props;
    const texts = settings.sections && settings.sections.texts;

    return (
      <Container>
        <Wrapper className={isMobileOpened ? '' : 'mobile-closed'}>
          {!isMobileOpened && <OpenSummaryButton onClick={() => onOpenMobile()} />}
          <WrapperInner>
            <CennikUpperPanel className={isMobileOpened ? '' : 'mobile-closed'}>
              {isMobileOpened && <OpenSummaryButton onClick={() => onOpenMobile()} />}
              <StyledSummaryPanelText className="hide-on-mobile-closed">
                {texts && texts[36] ? texts[36] : ''}
              </StyledSummaryPanelText>
              <CennikSummaryPanelContentPrice
                title={texts && texts[37] ? texts[37] : ''}
                priceNet={prices.monthly.total.net}
                priceGross={prices.monthly.total.gross}
                className="hide-on-mobile-closed"
                texts={texts}
              />
              <CennikSummaryPanelContentPrice
                title={texts && texts[38] ? texts[38] : ''}
                priceNet={prices.oneOff.total.net}
                priceGross={prices.oneOff.total.gross}
                className="hide-on-mobile-closed"
                texts={texts}
              />
            </CennikUpperPanel>
            <StyledCennikSummaryPanelItem>
              {utils.SafeHtml(texts && texts[39] ? texts[39] : '')}
            </StyledCennikSummaryPanelItem>
            <StyledButtonWrapper data-tip="React-tooltip" data-for="buyDisabled">
              <StyledBuyButtonLink
                // to="/sklep/krok-4"
                disabled={!(basket.products.length && basket.tariff && basket.fiscalization)}
                onClick={this.props.onBuyClick}
              >
                {texts && texts[40] ? texts[40] : ''}
              </StyledBuyButtonLink>
            </StyledButtonWrapper>
          </WrapperInner>
        </Wrapper>
      </Container>
    );
  }
}

export default ShoppingSummary;
