import React from 'react';
import styled from '@emotion/styled';
import { colors, dimensions, respondFrom, breakpoints, css } from '@styles';
import * as utils from '@utils';

const Wrapper = styled.div`
  padding: 30px 0;
  white-space: nowrap;
  background-color: ${colors.text.default};
  border-bottom: ${colors.text.medium} solid 1px;

  ${respondFrom(
    breakpoints.xl,
    css`
      flex: 1 0 0;
      border-bottom: 0;
      border-left: ${colors.text.medium} solid 1px;
      padding: 10px 30px;
      text-align: center;
    `
  )};
`;

const WrapperInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PriceTitle = styled.div`
  font-size: ${dimensions.fontSize.small}px;
  font-weight: 300;
`;

const PriceNet = styled.div`
  font-size: 30px;
  font-weight: 300;
  line-height: 1em;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const PriceGross = styled.div`
  font-size: ${dimensions.fontSize.small}px;
  line-height: 1em;
  white-space: nowrap;
  margin-bottom: 15px;
`;

interface Props {
  title: string;
  priceGross: number;
  priceNet: number;
  className?: string;
  texts: Array<string>;
}

export default (props: Props) => (
  <Wrapper className={props.className || ''}>
    <WrapperInner>
      <PriceNet>
        {`${utils.formatCurrency(props.priceNet.toFixed(props.priceNet % 1 === 0 ? 0 : 2))} ${
          props.texts && props.texts[21] ? props.texts[21] : ''
        }`}
      </PriceNet>
      <PriceGross>
        {`${utils.formatCurrency(props.priceGross.toFixed(2))} ${
          props.texts && props.texts[22] ? props.texts[22] : ''
        }`}
      </PriceGross>
      <PriceTitle>{props.title}</PriceTitle>
    </WrapperInner>
  </Wrapper>
);
