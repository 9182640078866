import React from 'react';
import styled from '@emotion/styled';
import { colors } from '@styles';

const StyledTooltipIcon = styled.span`
  min-width: 17px;
  min-height: 17px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.blue.default};
  border-radius: 50%;
  color: ${colors.white};
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
  margin-left: 5px;
  z-index: 2;
  cursor: default;
`;

const TooltipIcon = (props) => {
    return (
        <StyledTooltipIcon {...props}>
            ?
        </StyledTooltipIcon>
    )
}

export default TooltipIcon;
