import React, { Component, createRef } from 'react';
import styled from '@emotion/styled';
import { colors, respondFrom, breakpoints, css, mixins } from '@styles';
import * as utils from '@utils';

import { LeasingInfo, ConfiguratorDependent, CennikStateDependent } from '@store/types';
import Modal from '@components/common/Modal';
import CennikSummaryPanelContent from '@components/cennik/CennikSummaryPanelContent';
import CennikExistingConfigurationModal from '@components/cennik/CennikExistingConfigurationModal';
import { Funding } from '@content/types/funding';
import { CennikSettings } from '@content/types/cennik';

const StyledCennikSummaryPanel = styled.div`
  position: relative;
  margin-bottom: 40px;
  height: 155px;
  ${respondFrom(
    breakpoints.xl,
    css`
      height: 135px;
    `
  )}
`;

const Wrapper = styled.div<CennikSummaryPanelStyleProps>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${colors.text.default};

  top: auto;
  z-index: 20;
  ${mixins.transitionDefault};

  ${respondFrom(
    breakpoints.lg,
    css`
      background-color: ${colors.text.default};
      box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
    `
  )}

  &.sticky {
    position: absolute;
    overflow: hidden;

    &.opened {
      overflow: visible;
    }
  }
`;

export interface CennikSummaryPanelProps extends ConfiguratorDependent, CennikStateDependent {
  leasingInfo: LeasingInfo;
  fundings: Funding[];
  settings: CennikSettings;
  langcode: string;
  urls: Array<string>;
}

interface CennikSummaryPanelStyleProps {
  mobileSummaryOpened?: boolean;
}

class CennikSummaryPanel extends Component<CennikSummaryPanelProps> {
  ref = createRef<HTMLDivElement>();
  state = {
    mobileSummaryOpened: false,
    modalOpened: false,
    stickyPanel: false,
  };

  componentDidMount() {
    document.addEventListener('scroll', this.checkIfPanelOverFooter);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.checkIfPanelOverFooter);
  }

  openModal = () => this.setState({ modalOpened: true });
  closeModal = () => this.setState({ modalOpened: false });

  getPrices = () => {
    return utils.prices.getConfiguratorPricesSummary(
      this.props.cennik.basket,
      this.props.cennik.paymentType,
      this.props.leasingInfo
    );
  };

  onToggleMobile = () => {
    return this.setState({ mobileSummaryOpened: !this.state.mobileSummaryOpened });
  };

  isVisible = () => {
    const basket = this.props.cennik.basket;
    return (
      basket.products.length || basket.fundings.length || basket.tariff
    );
  };

  replaceBasketAndNavigateToConfigurator = () => {
    this.props.configuratorActions.replaceBasket(this.props.cennik);
    utils.navigate(utils.langLink(this.props.langcode, this.props.urls[8]));
  };

  buyClickAction = () => {
    if (this.props.configurator.basket.products.length) {
      this.openModal();
    } else {
      this.replaceBasketAndNavigateToConfigurator();
    }
  };

  checkIfPanelOverFooter = () => {
    const mainComponent = this.ref.current;
    const heightToFooter = mainComponent ? mainComponent.getBoundingClientRect().bottom : null;
    const windowHeight = window.innerHeight;
    let sticky = false;

    if (heightToFooter && heightToFooter <= windowHeight) {
      sticky = true;
    }

    this.setState({ stickyPanel: sticky });
  };

  render() {
    const { cennikActions, cennik, leasingInfo, settings } = this.props;

    return (
      <StyledCennikSummaryPanel ref={this.ref}>
        <Wrapper
          mobileSummaryOpened={this.state.mobileSummaryOpened}
          className={`
          ${this.state.mobileSummaryOpened ? 'opened' : ''}
          ${this.state.stickyPanel || !this.isVisible() ? 'sticky' : ''}
        `}
        >
          <CennikSummaryPanelContent
            cennikActions={cennikActions}
            basket={cennik.basket}
            active={cennik.basket.products.length > 0}
            onOpenMobile={this.onToggleMobile}
            isMobileOpened={this.state.mobileSummaryOpened}
            prices={this.getPrices()}
            paymentType={cennik.paymentType}
            leasingInfo={leasingInfo}
            onBuyClick={this.buyClickAction.bind(this) /* TODO: only if configurator has prods */}
            settings={settings}
          />
        </Wrapper>

        <Modal
          isOpened={this.state.modalOpened}
          content={<CennikExistingConfigurationModal {...this.props} />}
          onCloseModalPanel={this.closeModal.bind(this)}
        />
      </StyledCennikSummaryPanel>
    );
  }
}

export default CennikSummaryPanel;
