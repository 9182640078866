import React from 'react';

import { Product, CennikStateDependent } from '@store/types';

import Container from '@components/layout/Container';
import CennikSectionHeader from '@components/cennik/CennikSectionHeader';
import ProductsList from '@components/configurator/products/ProductsList';
import { LocationDependent } from '@content/types/general';
import { CennikSettings } from '@content/types/cennik';
import * as utils from '@utils';
import styled from '@emotion/styled';

const StyledCennikProducts = styled.section`
  margin-bottom: 20px;
`;

export interface CennikProductsProps extends CennikStateDependent, LocationDependent {
  products: Product[];
  badge?: string;
  settings: CennikSettings;
  langcode: string;
  children: React.ReactElement;
}

export default (props: CennikProductsProps) => (
  <StyledCennikProducts id="section-products">
    <Container>
      <CennikSectionHeader title={utils.SafeHtml(props.settings.sections.title1)} />
      {props.children}
      <ProductsList
        products={props.products}
        location={props.location}
        configurator={props.cennik}
        configuratorActions={props.cennikActions}
        basketMode="single"
        iconPlus={true}
        badge={props.badge}
        pageSettings={props.settings.configuratorFields}
        langcode={props.langcode}
        sliderSettings={{
          responsive: [
            {
              breakpoint: 768,
              settings: {
                arrows: false,
                slidesToShow: 1,
              },
            },
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 2.5,
              },
            },
          ],
        }}
      />
    </Container>
  </StyledCennikProducts>
);
